import NoPermissionsTooltipWrapper from "@components/ui/NoPermissionsTooltipWrapper";
import FlexRow from "@components/ui/flexBox/FlexRow";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import HorizontalDivider from "@src/deprecatedDesignSystem/components/HorizontalDivider";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import Text from "@ui/text";
import { usePrevious } from "@hooks/usePrevious";
import { StyleSheet, css } from "aphrodite";
import React, { FC, useCallback } from "react";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
import ContextMenuItem from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import SimpleDropdown from "@src/components/libraryItemDetailPages/course/SimpleDropdown";
import { ModalRootZIndex } from "@src/components/layout/ModalRoot";

export type MultiSelectAction = {
  icon?: DeprecatedIconType;
  key: string;
  label?: string;
  hasPermission?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  component?: React.ReactNode;
};

export type MultiSelectOnDestroy = undefined | (() => void);
export type Props = {
  numSelected: number;
  onDeselectAll: () => void;
  onDestroy?: MultiSelectOnDestroy;
  destroyLabel?: string;
  hasDestroyPermission?: boolean;
  actions?: MultiSelectAction[];
  hidden?: boolean;
  selectAll?: () => void;
  selectAllLoading?: boolean;
  totalItemCount?: number;
};

const MultiSelectBanner: FC<Props> = (
  {
    numSelected,
    onDeselectAll,
    actions,
    onDestroy,
    hasDestroyPermission = true,
    hidden,
    selectAll,
    totalItemCount,
    selectAllLoading,
    destroyLabel = "Remove",
  },
  ref,
) => {
  const previousSelectAllLoading = usePrevious(selectAllLoading);
  if (numSelected === 0 || hidden) {
    return null;
  }
  const selectAllLoadingComplete =
    previousSelectAllLoading && !selectAllLoading;
  return (
    <div className={css(styles.container)} ref={ref}>
      <FlexRow style={{ padding: 12 }}>
        <DeprecatedIcon
          type="close"
          onClick={onDeselectAll}
          style={{ marginRight: 8 }}
        />
        <Text type="P2" fontWeight="SemiBold">
          {numSelected} selected
        </Text>
        <AutoLayout
          direction="horizontal"
          alignmentVertical="center"
          marginLeft={16}
          styleDeclaration={styles.protip}
        >
          <Text type="P3" color={deprecatedTones.gray7}>
            <Text type="P3" fontWeight="SemiBold" color={deprecatedTones.gray7}>
              Pro tip:&nbsp;
            </Text>
            Hold the
          </Text>
          <AutoLayout
            direction="horizontal"
            alignmentVertical="center"
            border={`1px solid ${deprecatedTones.gray5Alpha}`}
            borderRadius={4}
            marginHorizontal={4}
            paddingHorizontal={4}
            paddingVertical={2}
          >
            <Text type="P3" color={deprecatedTones.gray7}>
              shift
            </Text>
          </AutoLayout>
          <Text type="P3" color={deprecatedTones.gray7}>
            key to select multiple rows with 1 click
          </Text>
        </AutoLayout>
        {selectAll &&
          numSelected !== totalItemCount &&
          !selectAllLoadingComplete && (
            <AutoLayout
              marginLeft={"auto"}
              style={{ cursor: "pointer" }}
              alignmentVertical="center"
            >
              {selectAllLoading ? (
                <Spinner size={16} style={{ marginRight: 8 }} />
              ) : (
                <AutoLayout
                  onClick={selectAll}
                  styleDeclaration={styles.actionContainer}
                  paddingHorizontal={12}
                >
                  <Text fontWeight="SemiBold" type="P2">
                    Select all {totalItemCount}
                  </Text>
                </AutoLayout>
              )}
            </AutoLayout>
          )}
      </FlexRow>
      <HorizontalDivider color={deprecatedTones.gray5Alpha} />
      <FlexRow style={{ padding: 8, overflowX: "auto" }}>
        {actions && (
          <AutoLayout
            direction="horizontal"
            spaceBetweenItems={12}
            alignmentVertical="center"
          >
            {actions.length > 1 && (
              <AutoLayout paddingLeft={4}>
                <Text type="P2">Actions:</Text>
              </AutoLayout>
            )}
            {actions.map(
              (action) =>
                action.component || (
                  <MultiSelectBannerButton action={action} key={action.key} />
                ),
            )}
          </AutoLayout>
        )}
        {onDestroy ? (
          <NoPermissionsTooltipWrapper
            hasPermission={hasDestroyPermission}
            caretPosition="Top"
            tooltipSide="bottom"
          >
            <span
              className={css(
                styles.actionContainer,
                styles.destroy,
                !hasDestroyPermission && styles.disabled,
              )}
              onClick={!hasDestroyPermission ? undefined : onDestroy}
            >
              <DeprecatedIcon type="trash-2" style={{ marginRight: 4 }} />
              <Text type="P2" fontWeight="SemiBold" inline>
                {destroyLabel}
              </Text>
            </span>
          </NoPermissionsTooltipWrapper>
        ) : null}
      </FlexRow>
    </div>
  );
};

// @ts-ignore
export default React.forwardRef(MultiSelectBanner);

type MultiSelectBannerButtonProps = {
  action: MultiSelectAction;
};

export const MultiSelectBannerButton: FC<MultiSelectBannerButtonProps> = ({
  action,
}) => {
  const lacksPermission = action.hasPermission === false;
  const onClickCallback = useCallback(() => {
    if (action.disabled || lacksPermission) {
      return;
    }
    if (action.onClick) {
      action.onClick();
    }
  }, [action, lacksPermission]);
  return (
    <div
      key={action.key}
      className={css(
        styles.actionContainer,
        (action.disabled || lacksPermission) && styles.disabled,
      )}
      onClick={onClickCallback}
    >
      {action.icon && (
        <DeprecatedIcon type={action.icon} style={{ marginRight: 4 }} />
      )}
      <Text type="P2" fontWeight="SemiBold" inline>
        {action.label}
      </Text>
    </div>
  );
};

export const smallerThanBreakpoint = `@media (max-width: 1340px)`;

export const PaginatedTableMultiselectBannerZIndex = ModalRootZIndex - 1;

const styles = StyleSheet.create({
  container: {
    backgroundColor: deprecatedTones.white,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: 8,
    bottom: 32,
    zIndex: PaginatedTableMultiselectBannerZIndex,
    position: "fixed",
    maxWidth: "60%",
    left: 0,
    right: 0,
    margin: "0 auto",
    boxShadow: shadows.dropdownShadow,
  },
  actionContainer: {
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginRight: 4,
    borderRadius: 8,
    padding: "4px 8px",
    ":hover": {
      backgroundColor: deprecatedTones.gray5Alpha,
    },
  },
  destroy: {
    marginLeft: "auto",
    marginRight: 0,
  },
  disabled: {
    opacity: 0.5,
    ":hover": {
      backgroundColor: "unset",
    },
  },
  protip: {
    [smallerThanBreakpoint]: {
      display: "none",
    },
  },
});

type MultiSelectBannerDropdownProps = {
  disabled?: boolean;
  buttonLabel: string;
  buttonIcon: DeprecatedIconType;
  buttonKey: string;
  dropdownOptions: {
    icon: DeprecatedIconType;
    label: string;
    onClick: () => void;
  }[];
};

export const MultiSelectBannerDropdown: FC<MultiSelectBannerDropdownProps> = (
  props,
) => {
  return (
    <SimpleDropdown
      usePortal={true}
      radixContentProps={{
        side: "top",
        align: "center",
      }}
      trigger={
        <AutoLayout>
          <MultiSelectBannerButton
            action={{
              label: props.buttonLabel,
              icon: props.buttonIcon,
              key: props.buttonKey,
              disabled: props.disabled,
            }}
          />
        </AutoLayout>
      }
      content={
        !props.disabled && (
          <AutoLayout
            direction={"vertical"}
            padding={8}
            style={{
              borderRadius: 8,
              backgroundColor: deprecatedTones.purple3,
              zIndex: 3,
              boxShadow: shadows.elevation6,
            }}
          >
            {props.dropdownOptions.map((option) => (
              <ContextMenuItem
                key={option.label}
                label={option.label}
                icon={option.icon}
                iconProps={{ color: deprecatedTones.purple9 }}
                hoverStyle={{
                  backgroundColor: deprecatedTones.purple5,
                }}
                onSelect={option.onClick}
              />
            ))}
          </AutoLayout>
        )
      }
    />
  );
};
