import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { CoverImageFragmentDoc, MediaFragmentDoc, TrainingResourceVersionFragmentDoc, ContentSharingPermissionFragmentDoc, TranslationSetFragmentDoc, ContentTagMembershipFragmentDoc, ImageUrlsFragmentDoc, AutomationFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, ConvertMediaToRichTextDocumentWorkflowFragmentDoc, BlastSentRecordFragmentDoc, TrainingResourceFragmentDoc } from '../../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignLibraryItemModal_LibraryItemQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type AssignLibraryItemModal_LibraryItemQuery = { __typename?: 'Query', LibraryItem?: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, path?: { __typename?: 'Path', id: number } | null, course?: { __typename?: 'Course', id: number } | null, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, name: { __typename?: 'TranslationSet', en: string }, trainingResource?: { __typename?: 'TrainingResource', publishedVersion?: { __typename?: 'TrainingResourceVersion', media?: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResourceVersions?: Array<{ __typename?: 'TrainingResourceVersion', id: string, trainingResource: { __typename?: 'TrainingResource', id: string } }> | null } | null } | null } | null } | null };

export type AssignLibraryItemModal_ExistingAssignmentsQueryVariables = Types.Exact<{
  input: Types.AssignmentsInput;
}>;


export type AssignLibraryItemModal_ExistingAssignmentsQuery = { __typename?: 'Query', Assignments: { __typename?: 'Assignments', objects: Array<{ __typename?: 'Assignment', id: string, status: AssignmentStatus, user: { __typename?: 'Employee', id: number } }> } };

export type AssignLibraryItemModalModuleMembersihps_PathContentMembershipFragment = { __typename?: 'PathContentMembership', id: number, course?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, sectionBreak?: { __typename?: 'SectionBreak', id: number, title: { __typename?: 'TranslationSet', en: string } } | null };

export type AssignLibraryItemModalModuleMembersihps_LibraryItemFragment = { __typename?: 'LibraryItem', id: string, path?: { __typename?: 'Path', id: number, publishedVersion?: { __typename?: 'PathVersion', pathContentMemberships: Array<{ __typename?: 'PathContentMembership', id: number, course?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, sectionBreak?: { __typename?: 'SectionBreak', id: number, title: { __typename?: 'TranslationSet', en: string } } | null }> } | null } | null };

export type AssignLibraryItemModalModuleMembersihps_LibraryItemQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type AssignLibraryItemModalModuleMembersihps_LibraryItemQuery = { __typename?: 'Query', LibraryItem?: { __typename?: 'LibraryItem', id: string, path?: { __typename?: 'Path', id: number, publishedVersion?: { __typename?: 'PathVersion', pathContentMemberships: Array<{ __typename?: 'PathContentMembership', id: number, course?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, sectionBreak?: { __typename?: 'SectionBreak', id: number, title: { __typename?: 'TranslationSet', en: string } } | null }> } | null } | null } | null };

export const AssignLibraryItemModalModuleMembersihps_PathContentMembershipFragmentDoc = gql`
    fragment AssignLibraryItemModalModuleMembersihps_PathContentMembership on PathContentMembership {
  id
  course {
    id
    libraryItem {
      id
      name {
        en
      }
      coverImage {
        ...CoverImage
      }
    }
  }
  sectionBreak {
    id
    title {
      en
    }
  }
}
    ${CoverImageFragmentDoc}`;
export const AssignLibraryItemModalModuleMembersihps_LibraryItemFragmentDoc = gql`
    fragment AssignLibraryItemModalModuleMembersihps_LibraryItem on LibraryItem {
  id
  path {
    id
    publishedVersion {
      pathContentMemberships {
        ...AssignLibraryItemModalModuleMembersihps_PathContentMembership
      }
    }
  }
}
    ${AssignLibraryItemModalModuleMembersihps_PathContentMembershipFragmentDoc}`;
export const AssignLibraryItemModal_LibraryItemDocument = gql`
    query AssignLibraryItemModal_LibraryItem($id: UUID!) {
  LibraryItem(id: $id) {
    id
    path {
      id
    }
    course {
      id
    }
    coverImage {
      ...CoverImage
    }
    name {
      en
    }
    type
    trainingResource {
      publishedVersion {
        media {
          ...Media
        }
      }
    }
  }
}
    ${CoverImageFragmentDoc}
${MediaFragmentDoc}`;

/**
 * __useAssignLibraryItemModal_LibraryItemQuery__
 *
 * To run a query within a React component, call `useAssignLibraryItemModal_LibraryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignLibraryItemModal_LibraryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignLibraryItemModal_LibraryItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignLibraryItemModal_LibraryItemQuery(baseOptions: Apollo.QueryHookOptions<AssignLibraryItemModal_LibraryItemQuery, AssignLibraryItemModal_LibraryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignLibraryItemModal_LibraryItemQuery, AssignLibraryItemModal_LibraryItemQueryVariables>(AssignLibraryItemModal_LibraryItemDocument, options);
      }
export function useAssignLibraryItemModal_LibraryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignLibraryItemModal_LibraryItemQuery, AssignLibraryItemModal_LibraryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignLibraryItemModal_LibraryItemQuery, AssignLibraryItemModal_LibraryItemQueryVariables>(AssignLibraryItemModal_LibraryItemDocument, options);
        }
export type AssignLibraryItemModal_LibraryItemQueryHookResult = ReturnType<typeof useAssignLibraryItemModal_LibraryItemQuery>;
export type AssignLibraryItemModal_LibraryItemLazyQueryHookResult = ReturnType<typeof useAssignLibraryItemModal_LibraryItemLazyQuery>;
export type AssignLibraryItemModal_LibraryItemQueryResult = Apollo.QueryResult<AssignLibraryItemModal_LibraryItemQuery, AssignLibraryItemModal_LibraryItemQueryVariables>;
export const AssignLibraryItemModal_ExistingAssignmentsDocument = gql`
    query AssignLibraryItemModal_ExistingAssignments($input: AssignmentsInput!) {
  Assignments(input: $input) {
    objects {
      id
      user {
        id
      }
      status
    }
  }
}
    `;

/**
 * __useAssignLibraryItemModal_ExistingAssignmentsQuery__
 *
 * To run a query within a React component, call `useAssignLibraryItemModal_ExistingAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignLibraryItemModal_ExistingAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignLibraryItemModal_ExistingAssignmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignLibraryItemModal_ExistingAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<AssignLibraryItemModal_ExistingAssignmentsQuery, AssignLibraryItemModal_ExistingAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignLibraryItemModal_ExistingAssignmentsQuery, AssignLibraryItemModal_ExistingAssignmentsQueryVariables>(AssignLibraryItemModal_ExistingAssignmentsDocument, options);
      }
export function useAssignLibraryItemModal_ExistingAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignLibraryItemModal_ExistingAssignmentsQuery, AssignLibraryItemModal_ExistingAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignLibraryItemModal_ExistingAssignmentsQuery, AssignLibraryItemModal_ExistingAssignmentsQueryVariables>(AssignLibraryItemModal_ExistingAssignmentsDocument, options);
        }
export type AssignLibraryItemModal_ExistingAssignmentsQueryHookResult = ReturnType<typeof useAssignLibraryItemModal_ExistingAssignmentsQuery>;
export type AssignLibraryItemModal_ExistingAssignmentsLazyQueryHookResult = ReturnType<typeof useAssignLibraryItemModal_ExistingAssignmentsLazyQuery>;
export type AssignLibraryItemModal_ExistingAssignmentsQueryResult = Apollo.QueryResult<AssignLibraryItemModal_ExistingAssignmentsQuery, AssignLibraryItemModal_ExistingAssignmentsQueryVariables>;
export const AssignLibraryItemModalModuleMembersihps_LibraryItemDocument = gql`
    query AssignLibraryItemModalModuleMembersihps_LibraryItem($id: UUID!) {
  LibraryItem(id: $id) {
    ...AssignLibraryItemModalModuleMembersihps_LibraryItem
  }
}
    ${AssignLibraryItemModalModuleMembersihps_LibraryItemFragmentDoc}`;

/**
 * __useAssignLibraryItemModalModuleMembersihps_LibraryItemQuery__
 *
 * To run a query within a React component, call `useAssignLibraryItemModalModuleMembersihps_LibraryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignLibraryItemModalModuleMembersihps_LibraryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignLibraryItemModalModuleMembersihps_LibraryItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignLibraryItemModalModuleMembersihps_LibraryItemQuery(baseOptions: Apollo.QueryHookOptions<AssignLibraryItemModalModuleMembersihps_LibraryItemQuery, AssignLibraryItemModalModuleMembersihps_LibraryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignLibraryItemModalModuleMembersihps_LibraryItemQuery, AssignLibraryItemModalModuleMembersihps_LibraryItemQueryVariables>(AssignLibraryItemModalModuleMembersihps_LibraryItemDocument, options);
      }
export function useAssignLibraryItemModalModuleMembersihps_LibraryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignLibraryItemModalModuleMembersihps_LibraryItemQuery, AssignLibraryItemModalModuleMembersihps_LibraryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignLibraryItemModalModuleMembersihps_LibraryItemQuery, AssignLibraryItemModalModuleMembersihps_LibraryItemQueryVariables>(AssignLibraryItemModalModuleMembersihps_LibraryItemDocument, options);
        }
export type AssignLibraryItemModalModuleMembersihps_LibraryItemQueryHookResult = ReturnType<typeof useAssignLibraryItemModalModuleMembersihps_LibraryItemQuery>;
export type AssignLibraryItemModalModuleMembersihps_LibraryItemLazyQueryHookResult = ReturnType<typeof useAssignLibraryItemModalModuleMembersihps_LibraryItemLazyQuery>;
export type AssignLibraryItemModalModuleMembersihps_LibraryItemQueryResult = Apollo.QueryResult<AssignLibraryItemModalModuleMembersihps_LibraryItemQuery, AssignLibraryItemModalModuleMembersihps_LibraryItemQueryVariables>;