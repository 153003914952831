import React, { CSSProperties, useMemo } from "react";
import useHover from "@hooks/useHover";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { css, StyleSheet } from "aphrodite";
import TableContentCell from "@src/deprecatedDesignSystem/components/table/TableContentCell";
import Checkbox from "@src/deprecatedDesignSystem/components/Checkbox";
import {
  MultiSelectConfig,
  RenderFns,
  ResponsivenessConfigs,
  RowConfig,
  RowHeight,
} from "@src/deprecatedDesignSystem/components/table/Table";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import TableRowOverflow from "@src/deprecatedDesignSystem/components/table/TableRowOverflow";
import { Route } from "nextjs-routes";
import { TextProps } from "@src/ui/text";

type TableRowProps<T, K extends string> = {
  item: T;
  columns: K[];
  renderFns: RenderFns<T, K>;
  renderFnsTextProps?: Partial<Record<K, Partial<TextProps>>>;
  multiselect?: MultiSelectConfig<T>;
  responsiveness?: ResponsivenessConfigs<K>;
  rowConfig?: RowConfig<T>;
  isSelected?: boolean;
  toggleItemSelected?: (item: T, holdingShift: boolean) => void;
  rowStyles?: CSSProperties;
  rowHeight: RowHeight;
  onRowClick?: (item: T) => void;
  rowClickLink?: (item: T) => Route | undefined | null;
  rowLinkOpenInNewTab?: boolean;
  overflowMenu?: (item: T) => React.ReactNode;
  disableRowClickOnColumns?: Set<K>;
  isSkeletonState?: boolean;
  activeColumn?: K;
};

export function TableContentRow<T, K extends string>({
  item,
  columns,
  renderFns,
  renderFnsTextProps,
  multiselect,
  responsiveness,
  isSelected,
  toggleItemSelected,
  rowHeight,
  rowStyles,
  onRowClick,
  rowClickLink,
  rowLinkOpenInNewTab,
  overflowMenu,
  disableRowClickOnColumns,
  isSkeletonState,
  activeColumn,
}: TableRowProps<T, K>): JSX.Element {
  const onClick = useMemo(() => {
    if (onRowClick) {
      return () => {
        onRowClick(item);
      };
    }
    return undefined;
  }, [item, onRowClick]);
  const [isHovered, hoverBind] = useHover();
  const isMultiSelectDisabled = useMemo(() => {
    return multiselect?.disableMultiSelectForItem?.(item) || false;
  }, [item, multiselect]);
  const menu = useMemo(
    () => (overflowMenu ? overflowMenu(item) : undefined),
    [item, overflowMenu],
  );
  const linkUrl: Route | null | undefined = useMemo(() => {
    if (rowClickLink) {
      return rowClickLink(item);
    } else {
      return null;
    }
  }, [item, rowClickLink]);
  return (
    <AutoLayout
      direction={"horizontal"}
      alignSelf={"stretch"}
      data-testid={isSkeletonState ? undefined : "table-row"}
    >
      <AutoLayout
        flex={1}
        className={css(
          styles.tableRowContainer,
          isSelected && styles.selectedBackgroundColor,
          isHovered &&
            !isSelected &&
            (onRowClick || !!linkUrl) &&
            styles.hoveredBackgroundColor,
          (onRowClick || !!linkUrl) && styles.clickable,
        )}
        style={{
          userSelect: "none",
          height: typeof rowHeight === "number" ? rowHeight : undefined,
          minHeight: 42,
          borderBottom: "1px solid",
          borderColor: deprecatedTones.gray5Alpha,
          ...rowStyles,
        }}
        {...hoverBind}
      >
        {multiselect && (
          <TableContentCell
            width={multiselect.checkboxColumnWidth}
            minWidth={multiselect.checkboxColumnWidth}
            alignContent={"Center"}
          >
            {(isHovered || isSelected) && !isSkeletonState ? (
              <Checkbox
                checked={isSelected || false}
                disabled={isMultiSelectDisabled}
                onChange={(v, e) => {
                  if (toggleItemSelected) {
                    toggleItemSelected(item, e.shiftKey);
                  }
                }}
              />
            ) : (
              multiselect.renderAvatarFn?.(item)
            )}
          </TableContentCell>
        )}
        {columns.map((column) => {
          const responsivenessConfig = responsiveness?.[column];
          return (
            <TableContentCell
              key={column}
              width={responsivenessConfig?.width}
              minWidth={responsivenessConfig?.minWidth}
              flex={responsivenessConfig?.flex}
              wrapHeight={
                typeof rowHeight === "number" ? false : rowHeight.wrap
              }
              linkUrl={
                disableRowClickOnColumns && disableRowClickOnColumns.has(column)
                  ? undefined
                  : linkUrl
              }
              linkOpenInNewTab={rowLinkOpenInNewTab}
              onClick={
                disableRowClickOnColumns && disableRowClickOnColumns.has(column)
                  ? undefined
                  : onClick
              }
              active={activeColumn === column}
              textProps={renderFnsTextProps?.[column]}
            >
              {renderFns[column]?.(item)}
            </TableContentCell>
          );
        })}
        {overflowMenu != null ? (
          <TableContentCell width={34} minWidth={34} alignContent={"Left"}>
            {menu ? (
              <TableRowOverflow menu={menu} showOverflow={!isSkeletonState} />
            ) : (
              <></>
            )}
          </TableContentCell>
        ) : null}
      </AutoLayout>
    </AutoLayout>
  );
}

const styles = StyleSheet.create({
  tableRowContainer: {
    backgroundColor: deprecatedTones.white,
    margin: 0,
    padding: 0,
  },
  selectedBackgroundColor: {
    backgroundColor: deprecatedTones.blue2,
  },
  hoveredBackgroundColor: {
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  overflowMenuContainer: {
    position: "absolute",
    top: 0,
    right: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    zIndex: 100,
  },
  overflowMenuButtonStyle: {
    pointerEvents: "auto",
  },
  dropdownMenuContainer: {
    position: "absolute",
    top: 48,
    right: 0,
    pointerEvents: "auto",
  },
  clickable: {
    cursor: "pointer",
  },
});
