import { FC, useContext } from "react";
import ModalContext from "../../contexts/ModalContext";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { AnimatePresence, motion } from "framer-motion";

export const ModalRootZIndex = 600;

export const ModalRoot: FC = () => {
  const { components } = useContext(ModalContext);
  return (
    <AnimatePresence>
      {components.map((x, i) => (
        <motion.div
          key={`modal-overlay-${i}`}
          // Leaving custom rgba values for now
          initial={{ backgroundColor: "rgba(144, 149, 157, 0)" }}
          animate={{ backgroundColor: deprecatedColors.modalOverlay }}
          exit={{ backgroundColor: "rgba(144, 149, 157, 0)" }}
          transition={{ duration: 0.2 }}
          style={{
            zIndex: ModalRootZIndex + i,
            position: "fixed",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <motion.div
            key={`modal-${i}`}
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.6 }}
            transition={{ duration: 0.2 }}
            style={{
              zIndex: ModalRootZIndex + i + 1,
            }}
          >
            {x}
          </motion.div>
        </motion.div>
      ))}
    </AnimatePresence>
  );
};

export default ModalRoot;
