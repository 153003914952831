import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { CoverImageFragmentDoc, MediaFragmentDoc, TrainingResourceVersionFragmentDoc, ContentSharingPermissionFragmentDoc, TranslationSetFragmentDoc, ContentTagMembershipFragmentDoc, ImageUrlsFragmentDoc, AutomationFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, ConvertMediaToRichTextDocumentWorkflowFragmentDoc, BlastSentRecordFragmentDoc, TrainingResourceFragmentDoc } from '../../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PathContentSelectionModal_LibraryItem2Fragment = { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null };

export type PathContentSelectionModal_PathContentMembershipFragment = { __typename?: 'PathContentMembership', id: number, course?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, skill?: { __typename?: 'Skill', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, trainingResource?: { __typename?: 'TrainingResource', id: string, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, sectionBreak?: { __typename?: 'SectionBreak', id: number, title: { __typename?: 'TranslationSet', en: string } } | null };

export type PathContentSelectionModal_LibraryItemFragment = { __typename?: 'LibraryItem', id: string, path?: { __typename?: 'Path', id: number, publishedVersion?: { __typename?: 'PathVersion', pathContentMemberships: Array<{ __typename?: 'PathContentMembership', id: number, course?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, skill?: { __typename?: 'Skill', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, trainingResource?: { __typename?: 'TrainingResource', id: string, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, sectionBreak?: { __typename?: 'SectionBreak', id: number, title: { __typename?: 'TranslationSet', en: string } } | null }> } | null } | null };

export type PathContentSelectionModal_LibraryItemQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type PathContentSelectionModal_LibraryItemQuery = { __typename?: 'Query', LibraryItem?: { __typename?: 'LibraryItem', id: string, path?: { __typename?: 'Path', id: number, publishedVersion?: { __typename?: 'PathVersion', pathContentMemberships: Array<{ __typename?: 'PathContentMembership', id: number, course?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, skill?: { __typename?: 'Skill', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, trainingResource?: { __typename?: 'TrainingResource', id: string, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, sectionBreak?: { __typename?: 'SectionBreak', id: number, title: { __typename?: 'TranslationSet', en: string } } | null }> } | null } | null } | null };

export const PathContentSelectionModal_LibraryItem2FragmentDoc = gql`
    fragment PathContentSelectionModal_LibraryItem2 on LibraryItem {
  id
  name {
    en
  }
  type
  coverImage {
    ...CoverImage
  }
}
    ${CoverImageFragmentDoc}`;
export const PathContentSelectionModal_PathContentMembershipFragmentDoc = gql`
    fragment PathContentSelectionModal_PathContentMembership on PathContentMembership {
  id
  course {
    id
    libraryItem {
      ...PathContentSelectionModal_LibraryItem2
    }
  }
  skill {
    id
    libraryItem {
      ...PathContentSelectionModal_LibraryItem2
    }
  }
  trainingResource {
    id
    libraryItem {
      ...PathContentSelectionModal_LibraryItem2
    }
  }
  sectionBreak {
    id
    title {
      en
    }
  }
}
    ${PathContentSelectionModal_LibraryItem2FragmentDoc}`;
export const PathContentSelectionModal_LibraryItemFragmentDoc = gql`
    fragment PathContentSelectionModal_LibraryItem on LibraryItem {
  id
  path {
    id
    publishedVersion {
      pathContentMemberships {
        ...PathContentSelectionModal_PathContentMembership
      }
    }
  }
}
    ${PathContentSelectionModal_PathContentMembershipFragmentDoc}`;
export const PathContentSelectionModal_LibraryItemDocument = gql`
    query PathContentSelectionModal_LibraryItem($id: UUID!) {
  LibraryItem(id: $id) {
    ...PathContentSelectionModal_LibraryItem
  }
}
    ${PathContentSelectionModal_LibraryItemFragmentDoc}`;

/**
 * __usePathContentSelectionModal_LibraryItemQuery__
 *
 * To run a query within a React component, call `usePathContentSelectionModal_LibraryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathContentSelectionModal_LibraryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathContentSelectionModal_LibraryItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePathContentSelectionModal_LibraryItemQuery(baseOptions: Apollo.QueryHookOptions<PathContentSelectionModal_LibraryItemQuery, PathContentSelectionModal_LibraryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathContentSelectionModal_LibraryItemQuery, PathContentSelectionModal_LibraryItemQueryVariables>(PathContentSelectionModal_LibraryItemDocument, options);
      }
export function usePathContentSelectionModal_LibraryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathContentSelectionModal_LibraryItemQuery, PathContentSelectionModal_LibraryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathContentSelectionModal_LibraryItemQuery, PathContentSelectionModal_LibraryItemQueryVariables>(PathContentSelectionModal_LibraryItemDocument, options);
        }
export type PathContentSelectionModal_LibraryItemQueryHookResult = ReturnType<typeof usePathContentSelectionModal_LibraryItemQuery>;
export type PathContentSelectionModal_LibraryItemLazyQueryHookResult = ReturnType<typeof usePathContentSelectionModal_LibraryItemLazyQuery>;
export type PathContentSelectionModal_LibraryItemQueryResult = Apollo.QueryResult<PathContentSelectionModal_LibraryItemQuery, PathContentSelectionModal_LibraryItemQueryVariables>;